import React from "react"
import { SnapList } from "react-snaplist-carousel"
import { Flex } from "./Flex"
import { Box } from "./Box"

export const Carousel: React.FC<{
  children: any
  snapList: any
  px?: number
  pb?: number | string
  pt?: number
  height?: string
  carouselRef?: any
}> = ({ children, snapList, px, pb, pt, height, carouselRef }) => {
  return (
    <Flex
      flexDirection="row"
      style={{ position: "relative" }}
      width="100%"
      height={height ? height : "calc(100% - 100px)"}
      px={px ?? 2}
      pb={pb ?? 2}
      pt={pt ?? 2}
      ref={carouselRef}
    >
      <Box style={{ position: "relative" }} width="100%">
        <SnapList direction="horizontal" width="100%" height="100%" ref={snapList}>
          {children}
        </SnapList>
      </Box>
    </Flex>
  )
}
