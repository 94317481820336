import * as React from "react"

export const Sound = ({ muted }) => {
  return (
    <svg width={13.726} height={10} viewBox="0 0 16.726 12.957">
      <g data-name="Group 8">
        <g data-name="Group 5">
          <path
            data-name="Union 1"
            d="M8.193 12.85L3.857 9.478H0v-6h3.857L8.193.105a.5.5 0 01.807.4v11.951a.5.5 0 01-.5.5.493.493 0 01-.307-.106z"
            fill="#fff"
          />
          {!muted && (
            <g data-name="Group 4">
              <g
                data-name="Group 3"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={1.5}
              >
                <path data-name="Path 4" d="M11.124 3.819a2.987 2.987 0 011.162 2.548A2.89 2.89 0 0111 8.982" />
                <path data-name="Path 5" d="M13.828 1.621a5.517 5.517 0 012.148 4.713c0 2.24-.992 4.146-2.374 4.834" />
              </g>
            </g>
          )}
        </g>
      </g>
    </svg>
  )
}
