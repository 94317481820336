import * as React from "react"

export const Play = (props) => {
  return (
    <svg width={7} height={11} viewBox="0 0 13 16" {...props}>
      <path
        data-name="Polygon 1"
        d="M12.406 8.589a.5.5 0 010 .822L.784 17.457a.5.5 0 01-.785-.411V.954A.5.5 0 01.784.543z"
        fill="#fff"
      />
    </svg>
  )
}
