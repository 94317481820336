import * as React from "react"

export const Pause = (props) => {
  return (
    <svg width={8} height={11} viewBox="0 0 11 12" {...props}>
      <g data-name="Group 6" fill="#fff">
        <path data-name="Rectangle 2" d="M0 0h4v12H0z" />
        <path data-name="Rectangle 3" d="M7 0h4v12H7z" />
      </g>
    </svg>
  )
}
