import React, { useEffect, useRef, useState } from "react"
import { Box } from "./Box"
import { Pause } from "./SVG/Pause"
import { Play } from "./SVG/Play"
import { Sound } from "./SVG/Sound"
import { Flex } from "./Flex"
import styled from "styled-components"
import { color } from "../helpers/color"

export const Video: React.FC<{
  videoSRC: string
  imageSRC?: string
  onImageLoad?: () => void
  mimeType?: string
  width: number
}> = ({ videoSRC, onImageLoad, width, mimeType, imageSRC }) => {
  const [playing, setPlaying] = useState(false)
  const [muted, setMuted] = useState(false)
  const [duration, setDuration] = useState(0)
  const [scrubberWidth, setScrubberWidth] = useState("0%")
  const videoRef = useRef(null)
  const seekBarRef = useRef(null)
  const resizedImgSrc = !!imageSRC ? imageSRC + `?w=${width}` : ""

  const onTimeupdate = () => {
    if (videoRef?.current?.currentTime) {
      seekBarRef.current.value = videoRef.current.currentTime
      const percentage = (seekBarRef.current.value / duration) * 100
      setScrubberWidth(percentage + "%")
    }
  }

  useEffect(() => {
    if (videoRef?.current?.duration) {
      setDuration(videoRef.current.duration)
    }
  }, [videoRef?.current?.duration])

  const onPlay = () => {
    if (videoRef?.current && !playing) {
      videoRef.current.play()
      setPlaying(true)
    } else if (videoRef?.current) {
      videoRef.current.pause()
      setPlaying(false)
    }
  }

  const onMute = () => {
    if (videoRef?.current && !muted) {
      setMuted(true)
    } else if (videoRef?.current) {
      setMuted(false)
    }
  }

  const onMouseUp = () => {
    if (seekBarRef?.current?.value && videoRef?.current?.currentTime) {
      videoRef.current.currentTime = seekBarRef.current.value
      const percentage = (seekBarRef.current.value / duration) * 100
      setScrubberWidth(percentage + "%")
    }
  }

  const onChange = () => {
    if (seekBarRef?.current?.value && videoRef?.current?.currentTime) {
      videoRef.current.currentTime = seekBarRef.current.value
      const percentage = (seekBarRef.current.value / duration) * 100
      setScrubberWidth(percentage + "%")
    }
  }

  return (
    <VideoWrapper style={{ position: "relative" }}>
      <Box onClick={onPlay}>
      <video
        onTimeUpdate={onTimeupdate}
        muted={muted}
        ref={videoRef}
        preload="metadata"
        width="100%"
        onLoadStart={onImageLoad}
        poster={resizedImgSrc}
      >
        <source src={videoSRC + "#t=0.01"} type={mimeType} />
      </video>
      </Box>
      <ControlsWrapper>
        <Flex flexDirection="row" flexWrap="nowrap" alignItems="flex-end">
          <Box height="42px" width="40px" px={2} pt={2} pb={1} onClick={() => onPlay()} style={{ cursor: "pointer" }}>
            {playing ? <Pause /> : <Play />}
          </Box>
          <ScrubberWrapper flexDirection="row" flexWrap="nowrap" alignItems="center" py={2}>
            <Scrubber />
            <ScrubberBackground scrubberWidth={scrubberWidth} />
            <ScrubberInput
              type="range"
              value={videoRef?.current?.currentTime || 0}
              min={0}
              step={0.1}
              max={duration}
              ref={seekBarRef}
              onChange={onChange}
              onMouseUp={onMouseUp}
            />
          </ScrubberWrapper>
          <Box height="42px" width="48px" px={2} pt={2} pb={1} onClick={() => onMute()} style={{ cursor: "pointer" }}>
            <Sound muted={muted} />
          </Box>
        </Flex>
      </ControlsWrapper>
    </VideoWrapper>
  )
}

const ScrubberWrapper = styled(Flex)`
  flex: 1;
  position: relative;

  input[type="range"] {
    -webkit-appearance: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    -webkit-appearance: none;
    cursor: pointer;
    background: white;
  }
`

const Scrubber = styled("div")`
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: white;
`

const ScrubberBackground = styled("div")<{ scrubberWidth: string }>`
  position: absolute;
  width: ${(p) => p.scrubberWidth};
  height: 1px;
  background-color: ${color("gray")};
`

const ControlsWrapper = styled("div")`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 48px;
`

const ScrubberInput = styled("input")`
  position: absolute;
  width: 100%;
`

const VideoWrapper = styled(Box)`
  ${ControlsWrapper} {
    display: none;
  }

  &:hover {
    ${ControlsWrapper} {
      display: block;
    }
  }
`
