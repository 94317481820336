import { Box, Flex, Spacer, Picture } from "../"
import { Video } from "../Video"
import { gql } from "apollo-boost"
import withData from "../../lib/apollo"
import { useLazyQuery } from "@apollo/react-hooks"
import { Sans } from "../Typography"
import styled from "styled-components"
import { color } from "../../helpers/color"
import { space } from "../../helpers/space"
import { useState, useRef } from "react"
import { useEffect } from "react"
import { Media } from "../../Components/Responsive"
import { Nav } from "../Nav"
import { Theme } from "../../lib/theme"

const PROJECT_QUERY = gql`
  query GetProject($photographerID: ID!) {
    allProjectAsset(where: { imagePhotographer: { _id: { matches: $photographerID } } }, sort: { _createdAt: ASC }) {
      _id
      imagePhotographer {
        _id
        name
        hideName
      }
      model
      title
      video {
        _key
        asset {
          _id
          url
          mimeType
        }
      }
      image {
        _key
        asset {
          _id
          url
          metadata {
            _key
            dimensions {
              _key
              aspectRatio
              width
              height
            }
          }
        }
      }
    }
    allPhotographer(sort: { _updatedAt: DESC }, where: { hide: { neq: true } }) {
      _id
      name
    }
  }
`

const ProjectAsset = ({ projectAsset, index, setImageIndex, onImageLoad, imagesLoaded }) => {
  const sentinalRef = useRef(null)
  const imageSRC = projectAsset?.image?.asset?.url
  const mimeType = projectAsset?.video?.asset?.mimeType
  const videoSRC = projectAsset?.video?.asset?.url

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && imagesLoaded) {
          setImageIndex(index + 1)
        }
      },
      {
        root: null,
        rootMargin: "16px",
        threshold: 0.7,
      }
    )
    if (sentinalRef.current) {
      observer.observe(sentinalRef.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [sentinalRef, imagesLoaded])

  return (
    <ProjectAssetWrapper key={imageSRC}>
      <Sentinal ref={sentinalRef} />
      {videoSRC ? (
        <Video videoSRC={videoSRC} mimeType={mimeType} width={2500} onImageLoad={onImageLoad} />
      ) : (
        <Picture src={imageSRC} alt={projectAsset.alt} width={2500} onLoad={onImageLoad} />
      )}
    </ProjectAssetWrapper>
  )
}

export const ProjectModal: React.FC<{
  assetID: string
  photographerID: string
  openModal: boolean
  router: any
  setOpenModal: (set: boolean) => void
  setProjectData: (set: boolean) => void
}> = withData(({ assetID, photographerID, openModal, setOpenModal, setProjectData, router }) => {
  const [imageIndex, setImageIndex] = useState(1)
  const [imagesLoadedCount, setImagesLoadedCount] = useState(0)
  const [projectID, setProjectID] = useState("")
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [assetsToRender, setassetsToRender] = useState([])
  const [loadProject, { data }] = useLazyQuery(PROJECT_QUERY, {
    variables: {
      photographerID,
    },
  })

  useEffect(() => {
    if (router.query.project && router.query.project !== projectID) {
      setProjectID(router.query.project)
      setImagesLoaded(false)
      setImageIndex(1)
      loadProject()
    }
  }, [router.query.project])

  const onImageLoad = () => {
    setImagesLoadedCount(imagesLoadedCount + 1)
  }

  useEffect(() => {
    if (data?.allProjectAsset && assetID) {
      const sortedImages = data?.allProjectAsset.sort((a, b) => {
        return a.image?.asset?._id === assetID ? -1 : b.image?.asset?._id === assetID ? 1 : 0
      })
      setassetsToRender(sortedImages)
    } else if (data?.allProjectAsset) {
      setassetsToRender(data?.allProjectAsset)
    }
  }, [data])

  useEffect(() => {
    if (imagesLoadedCount >= data?.allProjectAsset.length && !imagesLoaded) {
      setTimeout(() => {
        setImagesLoaded(true)
      }, 400)
    }
  }, [imagesLoadedCount])

  const photographers = data?.allPhotographer
  const thisPhotographerIndex = photographers?.findIndex((p) => p._id === photographerID)
  const previousPhotographer =
    photographers && thisPhotographerIndex === 0
      ? photographers?.[photographers?.length - 1 || 0]
      : photographers?.[thisPhotographerIndex - 1 || 0]
  const nextPhotographer =
    thisPhotographerIndex === photographers?.length - 1
      ? photographers?.[0]
      : photographers?.[thisPhotographerIndex + 1 || 0]

  const photographerName = data?.allProjectAsset?.[0]?.imagePhotographer?.name
  const hideName = data?.allProjectAsset?.[0]?.imagePhotographer?.hideName

  const onNext = () => {
    setImagesLoaded(false)
    setImageIndex(1)
    setProjectData({ photographerID: nextPhotographer._id, assetID: null })
    window && window.scrollTo(0, 0)
  }
  const onBack = () => {
    setImagesLoaded(false)
    setImageIndex(1)
    setProjectData({ photographerID: previousPhotographer._id, assetID: null })
    window && window.scrollTo(0, 0)
  }

  let photographerCaption

  if (hideName) {
    photographerCaption = photographerName
  } else {
    photographerCaption = `PHOTOGRAPHY BY ${photographerName}`
  }

  return (
    <Theme>
      <ModalWrapper openModal={openModal}>
        <Media greaterThan="sm">
          <Flex flexDirection="column" style={{ flex: 1 }} justifyContent="space-between" p={1} height="100%">
            <NavWrapper>
              <Flex
                flexDirection="column"
                style={{ height: "100%", width: "100%" }}
                justifyContent="space-between"
                p={1}
              >
                <Box p={1}>
                  <StyledText size="1" onClick={() => setOpenModal(false)}>
                    CLOSE
                  </StyledText>
                </Box>
                <Box p={1}>
                  {!!assetsToRender?.length && imagesLoaded && (
                    <Sans size="1" style={{ textAlign: "right" }}>
                      {`${imageIndex} / ${assetsToRender?.length}`}
                    </Sans>
                  )}
                </Box>
                <Box p={1}>
                  <Sans size="1" style={{ textAlign: "right" }}>
                    <StyledSpan onClick={onBack}>BACK</StyledSpan>
                    <span> / </span>
                    <StyledSpan onClick={onNext}>NEXT</StyledSpan>
                  </Sans>
                </Box>
              </Flex>
            </NavWrapper>
            <Box width="calc(100% - 260px)" style={{ maxWidth: "1250px" }}>
              {!imagesLoaded && (
                <LoadingWrapper p={2}>
                  <LoadingMessage size="1">Loading</LoadingMessage>
                </LoadingWrapper>
              )}
              {assetsToRender?.map((projectAsset, index) => {
                return (
                  <Box
                    style={{
                      opacity: imagesLoaded ? 1 : 0,
                      transition: imagesLoaded ? "opacity 0.5s ease-in" : "none",
                    }}
                    key={index}
                    p={1}
                  >
                    <ProjectAsset
                      imagesLoaded={imagesLoaded}
                      onImageLoad={onImageLoad}
                      projectAsset={projectAsset}
                      index={index}
                      setImageIndex={setImageIndex}
                    />
                  </Box>
                )
              })}
              {!!photographerName && imagesLoaded && (
                <Box px={1} pb={1} pt="7px">
                  {!!photographerName && <Sans size="0">{photographerCaption}</Sans>}
                </Box>
              )}
            </Box>
          </Flex>
        </Media>
        <Media lessThan="md">
          <Box p={1} py="40px">
            {assetsToRender?.map((projectAsset, index) => {
              return (
                <Box style={{ opacity: imagesLoaded ? 1 : 0, transition: "opacity 0.5s ease-in" }} key={index} p={1}>
                  <ProjectAsset
                    imagesLoaded={imagesLoaded}
                    onImageLoad={onImageLoad}
                    projectAsset={projectAsset}
                    index={index}
                    setImageIndex={setImageIndex}
                  />
                </Box>
              )
            })}
            {!!photographerName && imagesLoaded && (
              <Box px={1} pb={1} pt="7px">
                {!!photographerName && <Sans size="0">{photographerCaption}</Sans>}
              </Box>
            )}
            <Nav nextSlide={onNext} previousSlide={onBack} onClose={() => setOpenModal(false)} />
          </Box>
        </Media>
      </ModalWrapper>
    </Theme>
  )
})

const LoadingWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
`

const ModalWrapper = styled(Box)<{ openModal: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: white;
  z-index: 5000;
  display: ${(p) => (p.openModal ? "block" : "none")};
`

const StyledText = styled(Sans)`
  text-align: right;
  cursor: pointer;
  &:hover {
    color: ${color("gray")};
  }
`

const LoadingMessage = styled(Sans)`
  position: absolute;
  top: 16;
  right: 16;
`

const NavWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 260px;
  height: 100%;
`

const StyledSpan = styled("span")`
  color: black;
  padding-bottom: ${space(2)}px;
  cursor: pointer;

  &:hover {
    color: ${color("gray")};
  }
`

const ProjectAssetWrapper = styled("div")`
  position: relative;
  width: 100%;
  img {
    width: 100%;
  }
`

const Sentinal = styled.div`
  position: absolute;
  top: 50%;
  height: 400px;
  width: 20px;
  z-index: 40;
  transform: translateY(-50%);
`
